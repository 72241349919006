import React, { useState } from 'react';
import Api from '../Auth/Api';
import { Toast } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Recuperar() {

    const navigate = useNavigate();

    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);

    const [email, setEmail] = useState("");


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await Api.post("/recuperacao-conta", { email });
            setToast({ header: 'Sucesso!', body: response.data.message })

        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error(error.response.data.message);
        }
        setShowToast(true);
    };

    return (
        <>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '50%', bottom: '10px', transform: 'translateX(-50%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            <nav className="navbar bg-primary">
                <div className="container-fluid">
                    <span className="d-flex justify-content-center w-100 navbar-brand m-5px">
                        <img src="./../assets/logo-white.png" className="cursor-pointer" alt="" />
                    </span>
                </div>
            </nav>
            <div className="fade-in w-100">
                <div className="container-fluid login-container">
                    <div className="row align-items-center justify-content-start">
                        <div className="col-12 col-md-12 col-xl-6 mx-md-5">
                            <span onClick={() => navigate(-1)} className="btn btn-link link border-0 fs-6 text-start mb-5"><i className="bi bi-chevron-left"></i>Voltar</span>
                            <form className="d-flex flex-column px-4" onSubmit={handleSubmit}>
                                <div className="d-flex flex-column mb-5">
                                    <h2 className="text-dark fs-4 mb-3">Esqueceu a senha?</h2>
                                    <p className="text-dark mb-0">Para recuperar sua senha insira o seu email cadastrado e enviaremos a instrução para recuperar sua senha</p>
                                </div>
                                <div className="mb-3">
                                    <input type="email" placeholder="Email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} required />
                                </div>
                                <div className="d-flex flex-column gap-4">
                                    <button type="submit" className="btn btn-primary text-light px-4 py-1 py-md-3">Recuperar</button>
                                </div>
                            </form>
                        </div>
                        <div className="d-none d-md-block col p-0 spacing-img"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Recuperar;
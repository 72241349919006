import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import Api from '../Auth/Api';


const styles = StyleSheet.create({
    page: {
        padding: 30,
        backgroundColor: '#E4E4E4'
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 20
    },
    subTitle: {
        fontSize: 16,
        textAlign: 'center',
        marginBottom: 20
    },
    question: {
        fontSize: 18,
        margin: 10,
        padding: 10
    },
    answer: {
        fontSize: 16,
        marginLeft: 20,
        marginTop: 5
    },
    correctAnswer: {
        color: 'green'
    },
    incorrectAnswer: {
        color: 'red'
    }
});

const GerarPDF = ({ data, titulo, tipo }) => {

    const token = JSON.parse(localStorage.getItem("user_token"));
    const [progressoAvaliacaoExtra, setProgressoAvaliacaoExtra] = useState(null);

    useEffect(() => {
        async function fetchData(id) {
            try {
                const response = await Api.get(`api/info-progresso-avaliacao-extra/${id}`, { headers: { Authorization: `Bearer ${token}` } });
                setProgressoAvaliacaoExtra(response.data);

            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        tipo === 'avaliacao_extra' && fetchData(data)
    }, [token, tipo, data]);

    data = progressoAvaliacaoExtra ? progressoAvaliacaoExtra : data

    if (!data.questoes || !data.respostas || !data.alternativa_correta) {
        return null;
    }

    const questoes = JSON.parse(data.questoes);
    const respostasUsuario = JSON.parse(data.respostas);
    const alternativasCorretas = data.alternativa_correta;

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Text style={styles.title}>Avaliação de {data.nomeUsuario}</Text>
                <Text style={styles.subTitle}>{titulo}</Text>
                {Object.keys(questoes).map(key => {
                    const questao = questoes[key];
                    const respostaUsuario = respostasUsuario[key];
                    const respostaCorreta = alternativasCorretas[key];
                    const isCorrect = respostaCorreta === respostaUsuario;

                    return (
                        <View key={key} style={styles.question}>
                            <Text>{questao.questao}</Text>
                            <Text
                                style={[
                                    styles.answer,
                                    isCorrect ? styles.correctAnswer : styles.incorrectAnswer
                                ]}
                            >
                                Resposta: {questao.resposta}
                            </Text>
                            {!isCorrect && questao.alternativas && respostaUsuario <= questao.alternativas.length && (
                                <Text style={styles.incorrectAnswer}>
                                    Resposta do usuário: {questao.alternativas[respostaUsuario - 1]}
                                </Text>
                            )}
                        </View>
                    );
                })}
                <Text>Total de Acertos: {data.acerto}</Text>
            </Page>
        </Document>
    );
};

export default GerarPDF;

import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Api from '../Auth/Api';
import { Toast, Modal } from 'react-bootstrap';
import ModalImage from "react-modal-image";

function Questionario() {

    const { id } = useParams();
    const token = JSON.parse(localStorage.getItem("user_token"));
    const [dataPerguntas, setDataPerguntas] = useState([]);
    const [respostas, setRespostas] = useState({});
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [enviarAvaliacao, setEnviarAvaliacao] = useState(false);
    const location = useLocation();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [tempoRestante, setTempoRestante] = useState(0);
    const [tempoTotal, setTempoTotal] = useState(0);
    const [acabouTempo, setAcabouTempo] = useState(false);
    const [nota, setNota] = useState(0);
    const navigate = useNavigate();

    let path, pathSubmit;
    if (location.pathname.includes('/p2/')) {
        path = 'avaliacoes-p2';
        pathSubmit = 'progresso-avaliacao-p2';
    } else if (location.pathname.includes('/extra/')) {
        path = 'avaliacoes-extra';
        pathSubmit = 'progresso-avaliacao-extra';
    } else {
        path = 'avaliacoes';
        pathSubmit = 'progresso-avaliacao';
    }

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/${path}/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataPerguntas(response.data);
            } catch (error) {
                console.error(error.response.data.message)
                navigate("/");
            }
        }
        fetchData();
    }, [token, id, navigate, path]);

    // Quando dataPerguntas for atualizado, atualize o estado do tempoRestante
    useEffect(() => {
        if (dataPerguntas[0] && dataPerguntas[0].tempo) {
            const tempoEmSegundos = dataPerguntas[0].tempo * 60;
            setTempoRestante(dataPerguntas[0].tempo * 60); // Converta minutos para segundos
            setTempoTotal(tempoEmSegundos);
        }
    }, [dataPerguntas]);


    // Configura um intervalo para diminuir o tempo a cada segundo
    useEffect(() => {
        const intervalo = setInterval(() => {
            setTempoRestante(tempo => Math.max(tempo - 1, 0));
        }, 1000);

        // Limpeza do intervalo quando o componente for desmontado
        return () => clearInterval(intervalo);
    }, []);

    // Converta o tempo restante em minutos e segundos para exibição
    const minutos = Math.floor(tempoRestante / 60);
    const segundos = tempoRestante % 60;

    // Calcular a porcentagem do tempo restante
    const porcentagemTempoRestante = tempoRestante / tempoTotal * 100;

    const [indiceAtual, setIndiceAtual] = useState(0);
    const perguntaAtual = dataPerguntas[indiceAtual];

    function proximaPergunta() {
        if (respostas[perguntaAtual.id] && indiceAtual < dataPerguntas.length - 1) {
            setIndiceAtual(indiceAtual + 1);
        } else {
            setToast({ header: 'Ops!', body: "Você precisa escolher uma resposta" })
            setShowToast(true)
        }

    }

    function perguntaAnterior() {
        if (indiceAtual > 0) {
            setIndiceAtual(indiceAtual - 1);
        }
    }

    function handleSelecao(perguntaId, alternativaIndex) {
        setRespostas(prev => ({
            ...prev,
            [perguntaId]: alternativaIndex
        }));
    }

    const confirmSubmit = async () => {

        if (!respostas || Object.keys(respostas).length === 0) {
            setToast({ header: 'Atenção!', body: 'Você não enviou nenhuma resposta.' })
            setShowToast(true)
            setShowConfirmationModal(false); // Fechar o modal
            navigate(`/aluno/dashboard`)
            return
        }

        setShowConfirmationModal(false); // Fechar o modal

        const formattedAnswers = {};

        for (let perguntaId in respostas) {
            console.log(perguntaId);
            const perguntaData = dataPerguntas.find(p => p.id.toString() === perguntaId);
            formattedAnswers[perguntaId] = {
                questao: perguntaData.questao,
                resposta: perguntaData.alternativas[respostas[perguntaId] - 1],
                alternativa: respostas[perguntaId].toString()
            };
        }

        const data = {
            respostas: formattedAnswers,
            idModulo: id,
            idCurso: id,
            idAvaliacao: id,
        }

        try {
            const response = await Api.post(`/api/${pathSubmit}/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setNota(response.data.nota);
            setToast({ header: 'Sucesso!', body: response.data.message })
            setTimeout(() => {
                setEnviarAvaliacao(true)
            }, 1000);
        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao enviar resposta:", error.response.data.message)
        }

        setShowToast(true)
    }

    // Ação ao acabar o tempo da avaliacao
    if (porcentagemTempoRestante <= 0) {
        if (!acabouTempo) {
            setAcabouTempo(true);
            confirmSubmit();
        }
    }

    return (
        <>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Você tem certeza que deseja enviar suas respostas?
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center bg-light">
                    <button onClick={confirmSubmit} className="btn btn-primary text-white px-5 py-2">
                        Enviar
                    </button>
                    <button className="btn btn-outline-primary text-primary px-5 py-2 ms-4" onClick={() => setShowConfirmationModal(false)} >
                        Cancelar
                    </button>
                </Modal.Footer>
            </Modal>


            <div className={`fade-in w-100`} style={{ marginBottom: '100px' }} key={indiceAtual}>
                <Link onClick={() => navigate(-1)} className="btn text-primary link border-0 fs-6 mt-3 mt-md-0"><i className="bi bi-chevron-left"></i>Voltar</Link>


                <div className={`body rounded px-3 py-3 d-flex justify-content-center align-items-center ${enviarAvaliacao && 'd-none'}`}>
                    <section className="col-12 col-lg-12 col-xl-8">
                        <div className="d-flex justify-content-center align-items-center bg-primary text-white p-3 rounded-top">
                            <h4 className="m-0">Questão {indiceAtual + 1}/{dataPerguntas.length}</h4>
                        </div>
                        <div className="p-4 bg-white rounded-bottom-4 shadow-sm">
                            <div className='row px-4 py-2'>
                                <p className="fs-5 mb-4">{perguntaAtual && perguntaAtual.questao}</p>
                                <hr className='px-5' />
                                {perguntaAtual && perguntaAtual.imagem &&
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <ModalImage className='border-1 rounded-3 card-img-question-edit' small={`${process.env.REACT_APP_IMG_URL}/${perguntaAtual.imagem}`} large={`${process.env.REACT_APP_IMG_URL}/${perguntaAtual.imagem}`} alt='' />
                                    </div>
                                }
                                {perguntaAtual && perguntaAtual.alternativas.map((alternativa, index) => (
                                    <div className="form-check mb-2 p-3" key={index}>
                                        <input
                                            className="form-check-input border-primary"
                                            type="radio"
                                            name={`pergunta-${perguntaAtual.id}`}
                                            id={`alternativa-${perguntaAtual.id}-${index + 1}`}
                                            value={index + 1}
                                            checked={respostas[perguntaAtual.id] === index + 1}
                                            onChange={() => handleSelecao(perguntaAtual.id, index + 1)}
                                        />
                                        <label className="form-check-label border-primary" htmlFor={`alternativa-${perguntaAtual.id}-${index + 1}`}>
                                            {alternativa}
                                        </label>
                                    </div>
                                ))}

                                <div className="d-flex flex-column flex-md-row justify-content-md-evenly mt-4 pt-5 pb-4">
                                    {indiceAtual > 0 &&
                                        <button onClick={perguntaAnterior} className="btn btn-outline-primary text-primary px-5 py-2 mb-2 mb-md-0">Questão Anterior</button>
                                    }
                                    {indiceAtual === dataPerguntas.length - 1 ?
                                        // Em vez de chamar submit diretamente, exiba o modal de confirmação
                                        <button className="btn btn-success text-light px-5 py-2 ms-md-4" onClick={() => setShowConfirmationModal(true)}>Enviar</button> :
                                        <button onClick={proximaPergunta} className="btn btn-primary text-light px-5 py-2 ms-md-4">Próxima Questão</button>
                                    }
                                </div>
                            </div>
                            <div className="progress w-100" style={{ height: '20px' }}>
                                <div className={`progress-bar progress-bar-striped ${porcentagemTempoRestante < 30 ? 'bg-primary' : 'bg-success'}`} role="progressbar" style={{ width: `${porcentagemTempoRestante}%` }} aria-valuenow={porcentagemTempoRestante} aria-valuemin="0" aria-valuemax="100">
                                    {minutos}:{segundos < 10 ? `0${segundos}` : segundos}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div className={`d-flex justify-content-center align-items-center ${!enviarAvaliacao && 'd-none'}`} style={{ marginTop: '60px' }}>
                    <section className="col-12 col-lg-12 col-xl-8">
                        <div className="d-flex justify-content-center align-items-center bg-primary text-white p-3 rounded-top">
                            <h4 className="m-0">Avaliação concluida!</h4>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center bg-primary text-white p-4 bg-white rounded-bottom-4 shadow-sm">
                            <i className={`bi ${nota >= 6 ? 'bi-check-circle-fill text-primary' : 'bi-dash-circle-fill text-danger' } mb-4`} style={{ fontSize: '6rem' }}></i>
                            <p className="fw-bold text-primary fs-5">{nota >= 6 ? 'Parabéns, você passou!' : 'Infelizmente você não passou, tente novamente.' }</p>
                        </div>
                    </section>
                </div>
            </div>


        </>
    );
}

export default Questionario;
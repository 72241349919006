import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Api from "../Auth/Api";
import { Toast } from "react-bootstrap";
import 'vidstack/styles/defaults.css';
import 'vidstack/styles/community-skin/video.css';

import { MediaCommunitySkin, MediaOutlet, MediaPlayer, MediaPoster } from '@vidstack/react';


function Aula() {

    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem("user_token"));
    const { id } = useParams();
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);

    const [dataInfoAula, setDataInfoAula] = useState([]);
    const arquivoPrincipal = dataInfoAula[0] && JSON.parse(dataInfoAula[0].arquivos)[0]
    const validExtensions = ["mp4", "avi", "mkv", "mov"]

    let urlPrincipal = '';
    const html_externo = dataInfoAula[0] && dataInfoAula[0].html_externo;
    if (html_externo) {
        urlPrincipal = html_externo;
    } else {
        urlPrincipal = `${process.env.REACT_APP_FILES_URL}/${arquivoPrincipal}&token=${token}`
    }

    console.log(urlPrincipal);

    let listaArquivos = [];

    if (dataInfoAula[0] && dataInfoAula[0].arquivos) {
        const parsedData = JSON.parse(dataInfoAula[0].arquivos);
        if (Array.isArray(parsedData)) {
            listaArquivos = parsedData;
        }
    }

    // Agora, você pode prosseguir com as operações no array, como filtragem:
    listaArquivos = listaArquivos.filter(item => item);

    // Remover o item com índice 0
    listaArquivos.splice(0, 1);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/info-aula/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataInfoAula(response.data);
            } catch (error) {
                console.error(error.response.data.message)
                navigate("/");
            }
        }

        fetchData();
    }, [token, id, navigate]);


    useEffect(() => {
        const arquivo = dataInfoAula[0] && JSON.parse(dataInfoAula[0].arquivos)[0];
        if (arquivo !== undefined) {
            window.scrollTo(0, 130);


            setTimeout(async () => {
                setToast({ body: "Aula marcada como assistida." })
                setShowToast(true);
                await Api.post(`api/progresso-aula/${id}`, { data: 'assistida' }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            }, 300000); // 5 minutos

        }
    }, [dataInfoAula, id, token]);

    console.log(dataInfoAula);
    return (
        <>
            <Toast show={showToast} bg='success' onClose={() => setShowToast(false)} autohide delay={5000} style={{ position: 'fixed', left: '95%', top: '110px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Body><span className="text-white">{toast.body}</span></Toast.Body>
            </Toast>

            {dataInfoAula[0] &&
                <div className="fade-in w-100 mb-5">
                    <Link onClick={() => navigate(-1)} className="btn text-primary link border-0 fs-6 my-3 my-md-0"><i className="bi bi-chevron-left"></i> Voltar </Link>
                    {arquivoPrincipal !== undefined &&
                        <div className="body">
                            <section className="w-100">
                                <div className="video rounded-3 overflow-hidden w-100 bg-dark h-100">
                                    {validExtensions.includes(dataInfoAula[0].extensao) ?
                                        <div className="video-player-container" style={{ zoom: 0.8 }}>
                                            <MediaPlayer
                                                title={dataInfoAula[0].titulo}
                                                src={urlPrincipal}
                                                aspectRatio={16 / 9}
                                                crossorigin="allow"
                                            >
                                                <MediaOutlet>
                                                    <MediaPoster alt={dataInfoAula[0].titulo} />
                                                </MediaOutlet>
                                                <MediaCommunitySkin />
                                            </MediaPlayer>
                                        </div>
                                        :
                                        <iframe className="doc_aula" src={urlPrincipal} width="100%" title={dataInfoAula[0].titulo} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    }
                                </div>
                            </section>
                        </div >
                    }

                    <div className="card mt-3 mb-5 py-3 px-4 bg-primary text-white back-image border-none">
                        <div className="d-flex align-items-center row g-0">
                            {
                                dataInfoAula[0].arquivos &&
                                listaArquivos.map((value, index) => {
                                    return (
                                        <div key={index} className="col-md-3 overflow-hidden bg-white text-center rounded m-2">
                                            <span className="d-flex bg-grey w-100 space-grey"></span>
                                            <Link target="_blank" to={`${process.env.REACT_APP_FILES_URL}/${value}&token=${token}`}>
                                                <button type="button" className="btn fs-6 text-primary link py-3">
                                                    Baixar conteúdo <i className="bi bi-download ms-2"></i>
                                                </button>
                                            </Link>
                                        </div>
                                    )
                                })
                            }


                            <div className="col-md-3">
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between mb-2">
                                        <span className="fs-3 fw-500">{dataInfoAula[0].titulo}</span>
                                    </div>
                                    <span className="fw-light text-break">
                                        {dataInfoAula[0].descricao}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            }

        </>
    )
}

export default Aula;
export function decodeJWT(token) {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    } catch (error) {
        console.error("Erro ao decodificar o token:", error);
        return null;
    }
}

export function formatDate(date) {
    const [year, month, day] = date.split("-");

    return `${day}/${month}/${year}`;
}

export function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);

    let day = date.getDate();
    day = day < 10 ? `0${day}` : day;

    const year = date.getFullYear();

    const hours = date.getHours();

    let month = date.getMonth() + 1;
    month = month < 10 ? `0${month}` : month;

    let minutes = date.getMinutes();
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${day}/${month}/${year}\n${hours}:${minutes}`;

}

export function formatDateSimple(inputDate) {
    // Verifica se a data está no formato esperado
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    if (!datePattern.test(inputDate)) {
        throw new Error('Data deve estar no formato YYYY-MM-DD');
    }
    
    // Extrai o ano e o mês da data
    const year = inputDate.substring(0, 4);
    const month = inputDate.substring(5, 7);
    
    // Retorna a data no novo formato
    return `${month}/${year.substring(2)}`;
}

export function truncateString(str, limit = 80) {
    if (str.length > limit) {
        return str.substring(0, limit) + '...';
    } else {
        return str;
    }
}

export function Linkify({ text }) {
    // eslint-disable-next-line 
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

    const segments = [];
    let match;
    let lastIndex = 0;

    // Enquanto houver URLs na string
    while ((match = urlPattern.exec(text)) !== null) {
        // Adiciona o texto antes da URL ao array de segmentos
        segments.push(text.substring(lastIndex, match.index));

        // Adiciona a URL ao array de segmentos
        segments.push(match[0]);

        // Atualiza o índice para a posição após a última URL encontrada
        lastIndex = match.index + match[0].length;
    }

    // Adiciona qualquer texto restante após a última URL ao array de segmentos
    segments.push(text.substring(lastIndex));

    return (
        <>
            {segments.map((segment, index) => {
                if (urlPattern.test(segment)) {
                    return (
                        <a key={index} href={segment} target="_blank" rel="noopener noreferrer">
                            {segment}
                        </a>
                    );
                }
                return segment;
            })}
        </>
    );
}

export function obterIniciais(nomeCompleto) {
    // Verifica se nomeCompleto é nulo ou indefinido
    if (nomeCompleto == null) {
        return ''; // Retorna uma string vazia ou algum valor padrão para iniciais
    }

    // Divide o nome completo em palavras
    const palavras = nomeCompleto.split(' ');

    // Inicializa uma variável para armazenar as iniciais
    let iniciais = '';

    // Itera pelas palavras e obtém a primeira letra de cada palavra
    for (let i = 0; i < palavras.length; i++) {
        const palavra = palavras[i];
        if (palavra.length > 0) {
            iniciais += palavra[0].toUpperCase(); // Converte a primeira letra para maiúscula
        }
    }

    return iniciais;
}

export function obterPrimeiroNome(nomeCompleto) {
    // Verifica se nomeCompleto é nulo ou indefinido
    if (nomeCompleto == null) {
        return ''; // Retorna uma string vazia ou algum valor padrão para iniciais
    }

    // Divide o nome completo em palavras
    const palavras = nomeCompleto.split(' ');

    // Inicializa uma variável para armazenar as iniciais
    let primeiroNome = '';

    // Itera pelas palavras e obtém a primeira letra de cada palavra
    for (let i = 0; i < palavras.length; i++) {
        const palavra = palavras[i];
        if (palavra.length > 0) {
            primeiroNome = palavra;
            break;
        }
    }

    return primeiroNome;
}

export function formatTime(value) {
    // Remove todos os caracteres não numéricos
    const onlyNums = value.replace(/[^\d]/g, '');

    // Formata no padrão 00:21
    if (onlyNums.length <= 2) {
        return onlyNums;
    }

    if (onlyNums.length <= 4) {
        return onlyNums.slice(0, 2) + ':' + onlyNums.slice(2);
    }
}

export function abreviarNome(nomeCompleto) {
    // Divide o nome completo em palavras
    const palavras = nomeCompleto.split(' ');

    // Inicializa uma variável para armazenar o nome abreviado
    let nomeAbreviado = '';

    // Itera pelas palavras e obtém a primeira letra de cada palavra
    for (let i = 0; i < palavras.length; i++) {
        const palavra = palavras[i];
        if (palavra.length > 0) {
            if (i === 0 || i === palavras.length - 1) {
                nomeAbreviado += ' ' + palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase();
            } else {
                if (palavra.toLowerCase() !== 'de' && palavra.toLowerCase() !== 'da' && palavra.toLowerCase() !== 'dos' && palavra.toLowerCase() !== 'das') {
                    nomeAbreviado += ' ' + palavra.charAt(0).toUpperCase() + '.';
                } else {
                    nomeAbreviado += ' ' + palavra.toLowerCase();
                }
            }
        }
    }

    return nomeAbreviado;
}
function SemPermissao() {

    return (
        <>
            <div className="fade-in w-100 mb-5">
                <span className="text-primary fs-1">Ops!</span>
                <p className="fs-6">Você não tem permissão para acessar essa página</p>
            </div>

        </>
    )
}

export default SemPermissao;
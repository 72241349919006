import React from 'react'
import { useTable, usePagination, useGlobalFilter } from "react-table"

function TableRelatoriosAcompanhamento({ columns, data, titulo = '' }) {

    const props = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 20 }

        },
        useGlobalFilter,
        usePagination,

    );
    const {
        getTableBodyProps,
        rows,
        prepareRow,
        // setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        // setPageSize,
        state: { pageIndex, pageSize, globalFilter }
    } = props;
    React.useEffect(() => {
    }, [globalFilter]);

    return (
        <>
            <div className="table-full-width mt-3 mb-3">
                <div className="table-responsive">
                    <table className="table align-middle mt-1 table-striped table-hover">
                        <thead>
                            <tr>
                                <th colSpan="11" className='bg-primary text-white rounded-top'>{titulo}</th>
                            </tr>
                        </thead>
                        <thead>
                            <tr className='border border-white text-nowrap'>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Matrícula</th>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Nome do Colaborador</th>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Unidade</th>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Título do Curso</th>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Nome do Treinamento</th>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Tipo</th>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Status Teórica</th>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Data Teórica</th>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Data Avaliação</th>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted text-center'>Nota Avaliação</th>
                                <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Emissão Certificado</th>
                            </tr>
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr className='text-nowrap' {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}

                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan="11" className='bg-primary text-white rounded-bottom'>
                                    <div className="table-footer d-flex justify-content-between align-items-center">
                                        <span>Exibindo {pageSize < rows.length ? pageSize : rows.length} de {rows.length} registros</span>
                                        <div>
                                            <span>Página {pageIndex + 1} de {pageOptions.length}</span>
                                            <button className="btn btn-outline-primary btn-sm ms-2 text-white" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                                <i className="bi bi-caret-left"></i>
                                            </button>
                                            <button className="btn btn-outline-primary btn-sm ms-1 text-white" onClick={() => nextPage()} disabled={!canNextPage}>
                                                <i className="bi bi-caret-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    )

}
export default TableRelatoriosAcompanhamento;
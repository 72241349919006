import { useState, useEffect, useMemo, useCallback } from "react";
import Api from "../../Auth/Api";
import { pdf } from "@react-pdf/renderer";
import GerarHistoricoEscolar from "../../Components/GerarHistoricoEscolar";
import TableHistoricoEscolar from "../../Components/TableHistoricoEscolar";

function HistoricoEscolarAluno() {

    const token = JSON.parse(localStorage.getItem("user_token"));

    //listar todos os cursos
    const [dataTopicos, setDataTopicos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`/api/listar-topicos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataTopicos(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token])

    // listar certificados
    const [dataHistoricoEscolar, setDataHistoricoEscolar] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`/api/listar-meu-historico-escolar`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataHistoricoEscolar(response.data);
            } catch (error) {
                setDataHistoricoEscolar([]); // Se não tiver dados, limpa a lista
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token])

    // Filtro para seleção por curso
    const [selectTopico, setSelectTopico] = useState(null);
    const FilterDataProvas = selectTopico ? dataHistoricoEscolar.filter(item => item.idTopico === selectTopico) : dataHistoricoEscolar;

    // Gerar PDF
    const loadPDFData = useCallback(async (data) => {
        try {
            // Buscar os dados para gerar o PDF
            const response = await Api.get(`api/info-historico-escolar/${data.idUsuario}/${data.idTopico}`, {
                headers: { Authorization: `Bearer ${token}` }
            });

            // Gerar o PDF e obter como Blob
            const blob = await pdf(<GerarHistoricoEscolar data={response.data} />).toBlob();

            // Criar uma URL para o Blob e abrir em uma nova aba
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');  // Abre o PDF em uma nova guia
        } catch (error) {
            console.error(error.response.data.message);
        }
    }, [token]);

    const columns = useMemo(
        () => [
            {
                Header: () => null, // Sem título para esta coluna
                id: 'icon',
                accessor: row => row.id,
                Cell: () => (
                    <div className="d-flex align-items-center justify-content-center">
                        <i className="bi bi-person-circle fs-3"></i>
                    </div>
                )
            },
            {
                Header: 'Aluno',
                accessor: 'nomeAluno'
            },
            {
                Header: 'Topico',
                accessor: 'nomeTopico'
            },
            {
                Header: () => null, // Sem título para esta coluna
                id: 'id',
                accessor: row => row.id,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="d-flex align-items-center justify-content-center">
                        <button
                            onClick={() => loadPDFData({
                                idUsuario: original.idAluno,
                                idTopico: original.idTopico,
                                topico: original.nomeTopico
                            })}
                            className="btn btn-outline-primary text-primary rounded px-2"
                        > <i className="bi bi-box-arrow-in-down"></i>
                        </button>
                    </div>
                )


            }
        ],
        [loadPDFData]
    )

    return (
        <>
            <div className="fade-in w-100">
                <span className="text-primary fs-1">Histórico Escolar</span>
                <TableHistoricoEscolar columns={columns} data={FilterDataProvas} filterSelect={dataTopicos} setSelectTopico={setSelectTopico} titulo="Lista de histórico escolar" />
            </div>
        </>
    )
}

export default HistoricoEscolarAluno;
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Api from '../Auth/Api';
import { Modal, Button } from 'react-bootstrap';

function PrimeiroLogin() {
    const navigate = useNavigate();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const token = JSON.parse(localStorage.getItem("user_token"));

    const handleAccept = async () => {
        try {
            await Api.post(`api/primeiro-login`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setShowSuccessModal(true);
            localStorage.setItem("primeiro_login", "1");
        } catch (error) {
            console.error('Erro ao enviar os termos de adesão:', error);
        }
    };

    const handleClose = () => {
        setShowSuccessModal(false);
        navigate('/trocar-senha'); // Redireciona para a home
    };

    return (
        <>
            <div className="fade-in w-100 mb-5">
                <span className="text-primary fs-1">Termo de adesão</span>
                <p className="fs-6">Antes de continuar, você deve ler e aceitar os termos abaixo</p>
                <div className="body rounded bg-white px-3 py-3">
                    <section className="w-100">
                        <div className="d-flex justify-content-center">
                            <iframe title="primeiro_login" src="../assets/termo.pdf" style={{ border: '0', width: '100%', height: '500px' }}></iframe>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <button className="btn btn-primary me-3" onClick={handleAccept}>Aceitar</button>
                        </div>
                    </section>
                </div>
            </div>

            <Modal show={showSuccessModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Sucesso</Modal.Title>
                </Modal.Header>
                <Modal.Body><b>Termos aceitos com sucesso!</b> <br/>Antes de acessar a plataforma você precisa definir uma nova senha!</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Definir nova senha
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default PrimeiroLogin;

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Modal, Toast } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Api from '../Auth/Api';
import Table from '../Components/Table';
import { useForm } from 'react-hook-form';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import GerarPDF from '../Components/GerarPDF';
import { Tooltip } from 'react-tooltip';
import { decodeJWT } from '../Components/jwtUtils';

function ListaAlunos() {

    const [showModalNotasInd, setShowModalNotasInd] = useState(false);
    const [showModalEnviarProva, setShowModalEnviarProva] = useState(false);
    const [showModalNota, setShowModalNota] = useState({ show: false });
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [usuarioSelecionado, setUsuarioSelecionado] = useState({});
    const [progressoAvaliacao, setProgressoAvaliacao] = useState('');
    const [shouldGeneratePDF, setShouldGeneratePDF] = useState({ show: false });
    const [progressoAvaliacaoUserID, setProgressoAvaliacaoUserID] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [selectedTipo, setSelectedTipo] = useState('favoritos');
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [checkedStates, setCheckedStates] = useState({});

    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem("user_token"));
    const nivel = decodeJWT(token).nivel;
    const { id } = useParams();

    const handleSwitchChange = useCallback(async (idUsuario) => {
        try {
            const response = await Api.post(`api/permissao-certificado/${id}/${idUsuario}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response && response.data) {
                setCheckedStates(prevState => ({
                    ...prevState,
                    [idUsuario]: response.data.permissao === 1
                }));
            }
        } catch (error) {
            console.error('Erro ao alterar permissão:', error);
            // Você pode adicionar uma lógica para mostrar um erro ao usuário aqui, se desejar
        }
    }, [id, token]);

    const onSubmitNota = async (data) => {

        data['tipo'] = showModalNota.tipo;

        try {
            const response = await Api.post(`/api/editar-nota-avaliacao/${showModalNota.id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message })

        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao editar a nota:", error);
        }

        setShowToast(true);
        setShowModalNota({ show: false })
        setShowModalNotasInd(true)
    };

    const [dataInfoCurso, setDataInfoCurso] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/info-curso/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataInfoCurso(response.data);
            } catch (error) {
                console.error(error.response.data.message)
                // navigate("/");
            }
        }

        fetchData();
    }, [token, id, navigate]);

    const [alunos, setAlunos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-usuarios-por-curso/${id}/${selectedTipo}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                // Aqui você inicializa o estado checkedStates
                const initialStates = {};
                response.data.forEach(usuario => {
                    initialStates[usuario.id] = usuario.certificado === 1;
                });
                setCheckedStates(initialStates);

                setAlunos(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token, id, navigate, toast, selectedTipo]);

    const filterSelect = [
        { value: 'todos', label: 'Todos' },
        { value: 'andamento', label: 'Em andamento' },
        { value: 'finalizados', label: 'Finalizados' }
    ]

    const filterTipo = [
        { value: 'favoritos', label: 'Favoritos' },
        { value: 'todos', label: 'Todos' }
    ]

    // Criando os filtros a partir da api do Alunos
    let filterAlunos = alunos;
    switch (selectedFilter) {
        case "todos":
            filterAlunos = alunos;
            break;
        case "andamento":
            filterAlunos = alunos.filter(value => parseInt(value.aulas_finalizadas) > 0 && parseInt(value.porcentagem_concluida) !== 100);
            break;
        case "finalizados":
            filterAlunos = alunos.filter(value => parseInt(value.porcentagem_concluida) === 100);
            break;
        default:
            break;
    }

    useEffect(() => {
        setProgressoAvaliacao('') // Limpa antes de recarregar
        if (shouldGeneratePDF.show && progressoAvaliacao && usuarioSelecionado.id === progressoAvaliacaoUserID) {
            const doc = <GerarPDF data={progressoAvaliacao} titulo={shouldGeneratePDF.nome} tipo='avaliacao' />;
            pdf(doc).toBlob().then(blob => {
                saveAs(blob, `avaliacao_${usuarioSelecionado.nome_completo}_${shouldGeneratePDF.nome}.pdf`);
                setShouldGeneratePDF({ show: false });  // Reset após gerar o PDF
            });
        }
    }, [shouldGeneratePDF, progressoAvaliacao, usuarioSelecionado.nome_completo, usuarioSelecionado.id, progressoAvaliacaoUserID]);

    //Listar avaliacao e respostas do usuario
    useEffect(() => {
        async function fetchData(idUsuario, tipo) {
            try {
                const response = await Api.get(`api/${tipo === 'EX' ? 'info-progresso-avaliacao-extra-usuario' : tipo === 'P2' ? 'info-progresso-avaliacao-p2-usuario' : 'info-progresso-avaliacao-usuario'}/${shouldGeneratePDF.id}/${idUsuario}`, { headers: { Authorization: `Bearer ${token}` } });
                setProgressoAvaliacao(response.data);
                setProgressoAvaliacaoUserID(idUsuario); // Defina o ID do usuário para o qual os dados se aplicam
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        if (usuarioSelecionado.id && shouldGeneratePDF.id) {
            fetchData(usuarioSelecionado.id, shouldGeneratePDF.tipo);
        }
    }, [usuarioSelecionado.id, id, token, shouldGeneratePDF]);

    const [dataModulos, setDataModulos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-progresso-avaliacao-curso/${id}/${usuarioSelecionado.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataModulos(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        usuarioSelecionado.id && fetchData();
    }, [id, token, usuarioSelecionado.id, showModalNotasInd])

    const columns = useMemo(
        () => [
            {
                Header: () => null,
                id: 'foto',
                Cell: () => (
                    <div className="d-flex align-items-center">
                        <div className="picture text-white bg-grey" style={{ fontSize: '22px', height: '42px', marginRight: '0px', width: '42px' }}>
                            <i className="bi bi-person" />
                        </div>
                    </div>
                )
            },
            {
                Header: () => null,
                accessor: 'nome_completo',
                Cell: ({ cell: { value }, row: { original } }) => (
                    <>
                        <div className=''>{value}</div>
                        <div className='badge bg-primary'>{original.codigo}</div>
                    </>
                )
            },
            {
                Header: () => null,
                accessor: 'status',
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="text-center">
                        <div className={`badge bg-${value === 'Pendente' ? 'dark' : value === 'Aprovado' ? 'success' : 'primary'}`}>{value}</div>
                    </div>
                )
            },
            {
                Header: () => null,
                accessor: 'email'
            },
            {
                Header: () => null,
                accessor: 'porcentagem_concluida',
                Cell: ({ cell: { value }, row: { original } }) => {
                    let texto = '';
                    let color = 'primary';
                    let width = 0;
                    let textColor = 'text-light';
    
                    if (parseInt(value) === 100) {
                        texto = 'Concluído';
                        color = 'success';
                        width = 100;
                    }
                    if (parseInt(value) < 100) {
                        texto = `${value}%`;
                        width = value;
                    }
                    if (parseInt(original.aulas_finalizadas) > 0 && parseInt(value) !== 100) {
                        texto = `Em andamento`;
                        width = 100;
                        color = 'none';
                        textColor = 'text-dark';
                    }
    
                    return (
                        <div className="progress">
                            <div className={`progress-bar progress-bar-striped progress-bar-animated bg-${color} ${textColor}`} style={{ width: width + '%' }}>
                                {texto}
                            </div>
                        </div>
                    )
                }
            },
            {
                Header: () => null,
                id: 'actions',
                accessor: row => row.id,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div>
                        <button className="btn btn-outline-primary justify-content-center align-items-center rounded px-2 me-2"
                            id="notas"
                            disabled={original.status === 'Pendente' ? true : false}
                            onClick={() => {
                                setShowModalNotasInd(true)
                                setUsuarioSelecionado({ id: original.id, nome_completo: original.nome_completo });
                            }}>
                            <i className="bi bi-file-text" />
                            <Tooltip anchorSelect="#notas" place="top">
                                Conferir notas
                            </Tooltip>
                        </button>
                    </div>
                )
            },
            {
                Header: () => null,
                id: 'certificado',
                accessor: row => row.id,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className='d-flex align-items-center justify-content-center'>
                        <div className="form-check form-switch">
                            <input
                                className="form-check-input"
                                disabled={(original.status === 'Aprovado' && nivel === "Administrador") ? false : true}
                                type="checkbox"
                                role="switch"
                                id="certificado"
                                checked={checkedStates[original.id] || false}
                                onChange={() => handleSwitchChange(original.id)}
                            />
                        </div>
                        <Tooltip anchorSelect="#certificado" place="top">
                            Permitir certificado
                        </Tooltip>
                    </div>
                )
            },
            {
                Header: 'Codigo',
                accessor: 'codigo',
                visible: false  // Esta coluna será filtrada, mas não exibida
            }
        ],
        [checkedStates, handleSwitchChange, nivel]
    );
    

    return (
        <>

            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            <Link onClick={() => navigate(-1)} className="btn text-primary link border-0 fs-6 mb-4"><i className="bi bi-chevron-left"></i>Voltar</Link>

            {dataInfoCurso[0] &&
                <div className="fade-in w-100">
                    <span className="text-primary fs-2">Análise geral dos alunos</span>
                    <p className="fs-6">
                        Lista de alunos disponíveis para o curso <span className='fw-bold'>{dataInfoCurso[0].nome}</span>
                    </p>

                    <Table columns={columns} data={filterAlunos} filterSelect={filterSelect} filterTipo={filterTipo} setSelectedTipo={setSelectedTipo} setSelectedFilter={setSelectedFilter} titulo={dataInfoCurso[0].nome} />

                </div>
            }

            <Modal size="lg" show={showModalNota.show} onHide={() => { setShowModalNota({ show: false }); setShowModalNotasInd(true) }}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Trocar Nota <span className='text-dark fs-5'>({usuarioSelecionado.nome_completo})</span></span>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="body">
                                <form onSubmit={handleSubmit(onSubmitNota)}>
                                    <div className="row">
                                        <div className='label text-start fs-6 py-2 px-3'>Qual a nota para <span className='fw-bold'>{showModalNota.nome}</span></div>
                                        <div className="col">
                                            <input type="number" {...register('nota', { required: true })} className="form-control" placeholder="Digite a nota" />
                                            <input type="hidden" {...register('idUsuario', { required: true })} />
                                        </div>
                                        <div className="col">
                                            <input type="submit" className="btn btn-primary text-white w-100" value="Trocar nota" />
                                        </div>
                                        {errors.nota && <span className='text-primary fs-7'>Nota é obrigatório.</span>}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="xl" show={showModalNotasInd} onHide={() => { setShowModalNotasInd(false); setUsuarioSelecionado({}); setDataModulos([]) }}>
                <Modal.Body>
                    <div className='row col-12'>
                        <p className="fw-semibold fs-2 text-primary text-center" style={{ marginBottom: '-5px' }}>{usuarioSelecionado.nome_completo}</p>
                        <p className="fw-semibold fs-4 text-dark text-center text-uppercase">{dataInfoCurso[0] && dataInfoCurso[0].nome}</p>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="body">
                                <div className="table-full-width mt-3 mb-3">
                                    <div className="table-responsive">
                                        <table className="table align-middle mt-1">
                                            <thead>
                                                <tr className='border border-white'>
                                                    <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted'>Nome</th>
                                                    <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted text-center'>Tipo</th>
                                                    <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted text-center'>Nota</th>
                                                    <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted text-center'>Nota Final</th>
                                                    <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted text-center'>Avaliação</th>
                                                    <th className='text-uppercase text-black-50 fw-bold fs-8 bg-muted text-center'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dataModulos && dataModulos.map((value, index) => {
                                                        const rowClass = index % 2 === 0 ? 'even-row' : 'odd-row';
                                                        return (
                                                            (value.progresso_avaliacao || value.progresso_avaliacao_extras) &&
                                                            <>
                                                                {value.progresso_avaliacao &&
                                                                    <tr key={`pa-${value.id}`}>
                                                                        <td className={`${rowClass} fw-semibold`}>{value.nome}</td>
                                                                        <td className={`${rowClass} text-center`}>{value.progresso_avaliacao.tipo}</td>
                                                                        <td className={`${rowClass} text-center fw-bold ${value.progresso_avaliacao.nota < 6 ? 'text-primary' : 'text-dark'}`}>{value.progresso_avaliacao.nota || 'N/A'}</td>
                                                                        <td className={`${rowClass} text-center fw-regular`}><span className={`badge bg-${value.nota_final >= 6 ? 'success' : 'primary'}`}>{value.nota_final && value.nota_final}</span></td>
                                                                        <td className={`${rowClass} text-center`}>
                                                                            <span className='text-primary cursor-pointer' onClick={() => { setShouldGeneratePDF({ show: true, id: value.id, nome: value.nome, tipo: value.progresso_avaliacao.tipo }) }}>Visualizar</span>
                                                                        </td>
                                                                    </tr>
                                                                }
                                                                {
                                                                    value.progresso_avaliacao_extras && value.progresso_avaliacao_extras.map((value, index) => {
                                                                        return (
                                                                            <tr key={`pae-${index}`}>
                                                                                <td className={rowClass}><div>{value.nomeAvaliacaoExtra}</div><span className='text-muted fw-bold fs-7'>{value.nomeModulo}</span></td>
                                                                                <td className={`${rowClass} text-center fw-regular`}>{value.tipo}</td>
                                                                                <td className={`${rowClass} text-wrap text-center fw-bold ${value.nota < 6 ? 'text-primary' : 'text-dark'}`}>{value.nota || 'N/A'}</td>
                                                                                <td className={`${rowClass} text-center fw-regular`}>-</td>
                                                                                <td className={`${rowClass} text-center`}>
                                                                                    <span className='text-primary cursor-pointer' onClick={() => { setShouldGeneratePDF({ show: true, id: value.idAvaliacao, nome: value.nomeAvaliacaoExtra, tipo: value.tipo }) }}>Visualizar</span>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                                <tr>
                                                                    <td colSpan={5} style={{ padding: 1 }} className='bg-dark'></td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={showModalEnviarProva} onHide={() => setShowModalEnviarProva(false)}>
                <Modal.Header closeButton>
                    <div className='row'>
                        <span className="fw-medium fs-4 text-primary">Enviar prova para...</span>
                        <span>Lorem ipsum dolor sit amet. Qui reprehenderit esse quo similique laudantium ex modi </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <section className="w-100 mt-5">
                        <div className="d-flex flex-column">
                            <div className="row d-flex align-items-center justify-content-between">
                                <div className="col-md-8">
                                    <div className="inner-addon right-addon">
                                        <i className="d-flex bi bi-search" />
                                        <input
                                            type="search"
                                            placeholder="Pesquise o aluno desejado...."
                                            className="form-control py-2"
                                        />
                                    </div>
                                </div>
                                <div className="d-flex mt-2 mt-md-0 align-items-center justify-content-end col-md-4">
                                    <select className="form-select py-2">
                                        <option disabled>Ordenação</option>
                                        <option value="1">Curso</option>
                                        <option value="2">Módulos</option>
                                        <option value="3">Em andamento</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="body">
                                <div className="table-full-width mt-3 mb-3">
                                    <div className="table-responsive">
                                        <table className="table align-middle mt-1">
                                            <tbody>
                                                <tr className='text-nowrap border-white'>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="picture text-white bg-grey" style={{ cursor: 'pointer', fontSize: '22px', height: '42px', marginRight: '0px', width: '42px' }}>
                                                                <i className="bi bi-person" />
                                                            </div>
                                                        </div></td>
                                                    <td>Lorem Ipsum</td>
                                                    <td className='text-center fw-bold'>(99) 99999-9999</td>
                                                    <td className='text-center'>email@email.com</td>
                                                    <td className='text-center fw-bold'>
                                                        <input className="form-check-input border-primary" type="checkbox" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mb-4">
                        <button type="submit" className="btn btn-primary text-white px-3 py-2">Enviar</button>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default ListaAlunos;
import { Link, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useEffect, useState } from "react";
import Api from "../Auth/Api";
import { Toast } from "react-bootstrap";

function TrocarSenha() {
    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem("user_token"));
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        const formData = new FormData();

        if (data.novaSenha !== data.novaSenhaRepeticao) {
            setToast({ header: 'Erro!', body: 'As senhas digitadas não são iguais' });
            setShowToast(true);
            return;
        }

        // Adiciona todos os campos do formulário ao formData
        Object.keys(data).forEach(key => {
            const value = data[key];
            if (typeof value === 'object' && value !== null && !(value instanceof FileList)) {
                formData.append(key, JSON.stringify(value));
            } else if (value instanceof FileList) {
                Array.from(value).forEach(file => {
                    formData.append(key, file);
                });
            } else {
                formData.append(key, value);
            }
        });

        try {
            const response = await Api.post(`api/editar-senha`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message });
            localStorage.setItem("primeiro_login", "2");
            setTimeout(() => {
                navigate('/');
            }, 2000);
        } catch (error) {
            setToast({ header: 'Erro!', body: error.response.data.message });
            console.log(error);
        }
        setShowToast(true);
    };

    // Carregar informações do usuario via api
    useEffect(() => {
        async function fetchData() {
            try {
                await Api.get(`api/meu-perfil`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token, setValue, toast]);

    return (
        <>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>


            <div className="fade-in w-100 mb-5">
                <Link onClick={() => navigate(-1)} className="btn text-primary link border-0 fs-6">
                    <i className="bi bi-chevron-left"></i> Voltar
                </Link>

                <div className="d-flex justify-content-center">
                    <form onSubmit={handleSubmit(onSubmit)} className="w-50 mt-1 d-grid">

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label text-primary">Nova senha</label>
                                <input {...register('novaSenha', { required: true })} type="password" className="form-control" />
                                {errors.senhaAtual && <span className="text-primary fs-7">Nova senha é obrigatória</span>}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label text-primary">Repita a nova senha</label>
                                <input {...register('novaSenhaRepeticao', { required: true })} type="password" className="form-control" />
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary text-white mx-auto">Salvar</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default TrocarSenha;
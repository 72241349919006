import { createContext, useEffect, useState } from "react"

export const AuthContext = createContext({})
export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState();

    useEffect(() => {
        const userToken = localStorage.getItem("user_token")
        if (userToken) {
            setUser(true)
            return
        }
    }, []);

    const signin = (data, result) => {

        if (result) {
            localStorage.setItem("user_token", JSON.stringify(data.token))
            localStorage.setItem("nome_completo", data.nome_completo)
            localStorage.setItem("foto", data.foto)
            localStorage.setItem("primeiro_login", data.primeiro_login)
            setUser(true)
            return
        }

    };

    const signout = () => {
        setUser(null)
        localStorage.removeItem("user_token")
        localStorage.removeItem("nome_completo")
        localStorage.removeItem("foto")
        localStorage.removeItem("primeiro_login")
        return
    };

    return (
        <AuthContext.Provider
            value={{ user, signin, signout }}>
            {children}
        </AuthContext.Provider>
    );
};
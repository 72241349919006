import { useMemo, useState, useEffect } from "react";
import TableTopicos from '../Components/TableTopicos';
import Api from '../Auth/Api';
import { useForm } from "react-hook-form";
import { Modal, Toast } from 'react-bootstrap';

function Topicos() {

    const token = JSON.parse(localStorage.getItem("user_token"));
    const [showModalEditarTopico, setShowModalEditarTopico] = useState({ show: false });
    const [showModalDeletarTopico, setShowModalDeletarTopico] = useState({ show: false });
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    const [dataTopicos, setDataTopicos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-topicos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataTopicos(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token, toast]);

    const onSubmitEditarTopico = async (data) => {

        try {
            const response = await Api.post(`api/editar-topico/${showModalEditarTopico.id}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message })
        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao criar nova aula:", error)
        }

        setShowToast(true)
    }

    const handleDeletarTopico = async (id) => {
        try {
            const response = await Api.delete(`api/deletar-topico/${id}`, { headers: { Authorization: `Bearer ${token}` } });
            setToast({ header: 'Sucesso!', body: response.data.message })
        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error(error.response.data.message)
        }
        setShowModalDeletarTopico({ show: false })
        setShowToast(true)
    }

    const columns = useMemo(
        () => [
            {
                Header: () => null,
                id: 'foto',
                Cell: () => (
                    <div className="d-flex align-items-center">
                        <div className="picture text-white bg-grey" style={{ fontSize: '22px', height: '42px', marginRight: '0px', width: '42px' }}>
                            <i className="bi bi-journals" />
                        </div>
                    </div>
                )
            },
            {
                Header: () => null,
                accessor: 'nome'
            },
            {
                Header: () => null,
                id: 'actions',
                accessor: row => row.id,
                Cell: ({ cell: { value }, row: { original } }) => (
                    <div className="text-end">
                        <button onClick={() => {
                            setShowModalEditarTopico({ show: true, id: original.id });
                            setValue('nome', original.nome);
                        }} className="btn btn-outline-primary justify-content-center align-items-center rounded px-2 me-2">
                            <i className="bi bi-pencil" />
                        </button>
                        <button onClick={() => {
                            setShowModalDeletarTopico({ show: true, id: original.id, nome: original.nome });
                        }} className="btn btn-outline-primary justify-content-center align-items-center rounded px-2 me-2">
                            <i className="bi bi-trash" />
                        </button>
                    </div>
                )
            }
        ],
        [setValue]
    )

    return (
        <>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            <div className="fade-in w-100 mt-3" style={{ marginBottom: '100px' }}>
                <span className="text-primary fs-1">Lista de Tema</span>
                <p className="fs-6">Consulte os temas cadatrados, filtre, edite e exclua.</p>
                <TableTopicos columns={columns} data={dataTopicos} nivel="tutor" titulo="Lista de Tema" />
            </div>

            <Modal size="lg" show={showModalEditarTopico.show} onHide={() => setShowModalEditarTopico({ show: false })}>
                <form onSubmit={handleSubmit(onSubmitEditarTopico)}>
                    <Modal.Header closeButton>
                        <span className="fw-semibold fs-4 text-primary">Editar Tema</span>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="body">
                                        <div className="d-flex">
                                            <div className="d-flex flex-column w-100">
                                                <div className="row mb-3">
                                                    <div className="col-12 col-md-12">
                                                        <div>
                                                            <label className="form-label text-primary fw-medium">Nome</label>
                                                            <input type="text" {...register("nome", { required: true })} placeholder="Nome do tema" className="form-control border-primary" />
                                                            {errors.nome && <span className='text-primary fs-7'>Nome para o tema é obrigatório.</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex justify-content-center bg-light">
                        <button type="submit" className="btn btn-primary text-white px-5 py-2">Salvar</button>
                        <button type="button" className="btn btn-outline-primary text-primary px-5 py-2" onClick={() => setShowModalEditarTopico(false)}>Cancelar</button>
                    </Modal.Footer>
                </form>
            </Modal>

            <Modal size="lg" show={showModalDeletarTopico.show} onHide={() => setShowModalDeletarTopico({ show: false })}>
                <Modal.Header closeButton>
                    <span className="fw-semibold fs-4 text-primary">Deletar tema</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="body">
                                    <div className="d-flex">
                                        <div className="d-flex flex-column w-100">
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>Tem certeza que deseja deletar o tema <b>{showModalDeletarTopico.nome}</b>?</p>
                                                    <p>Essa ação ira deletar o tema e todo seu conteúdo, não será possível recuperar.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center bg-light">
                    <button type="submit" className="btn btn-primary text-white px-5 py-2" onClick={() => handleDeletarTopico(showModalDeletarTopico.id)}>Confirmar</button>
                    <button type="button" className="btn btn-outline-primary text-primary px-5 py-2" onClick={() => setShowModalDeletarTopico({ show: false })}>Cancelar</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Topicos;
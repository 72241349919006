import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from "react";
import Api from "../Auth/Api";
import { Toast } from "react-bootstrap";
import Select from 'react-select';

function Configuracoes() {
    const navigate = useNavigate();
    const token = JSON.parse(localStorage.getItem("user_token"));
    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);
    const [userPhoto, setUserPhoto] = useState(null);

    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        const formData = new FormData();

        // Adiciona todos os campos do formulário ao formData
        Object.keys(data).forEach(key => {
            const value = data[key];
            if (typeof value === 'object' && value !== null && !(value instanceof FileList)) {
                formData.append(key, JSON.stringify(value));
            } else if (value instanceof FileList) {
                Array.from(value).forEach(file => {
                    formData.append(key, file);
                });
            } else {
                formData.append(key, value);
            }
        });

        // Adiciona a imagem, se selecionada
        if (selectedImage) {
            formData.append('foto_perfil', selectedImage);
        }

        try {
            const response = await Api.post(`api/editar-meu-perfil`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message });
            localStorage.setItem("nome_completo", data.nome_completo)
        } catch (error) {
            setToast({ header: 'Erro!', body: error.response.data.message });
            console.log(error);
        }
        setShowToast(true);
    };

    // Carregar unidades
    const [dataUnidades, setDataUnidades] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-unidades`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataUnidades(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token]);

    // Carregar informações do usuario via api
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/meu-perfil`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setValue('nome_completo', response.data.nome_completo);
                setValue('unidade', response.data.unidade);
                setValue('email', response.data.email);
                localStorage.setItem("foto", response.data.foto)
                setUserPhoto(response.data.foto);
                const selectUnidade = dataUnidades.find(item => item.nome === response.data.unidade);
                if (selectUnidade && selectUnidade.nome) {
                    setValue('unidade', { value: selectUnidade.nome, label: selectUnidade.nome });
                } else {
                    setValue('unidade', { value: '', label: '' });
                }

            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token, setValue, toast, dataUnidades]);


    const customStyles = {
        control: (base, state) => ({
            ...base,
            fontSize: "1rem",
            backgroundColor: "#ffffff",
            borderRadius: "0.25rem",
            boxShadow: state.isFocused ? "0 0 0 0.25rem #27485b73" : null,
            borderColor: "#dee2e6",
            "&:hover": {
                borderColor: "$dee2e6"
            },
        }),
        singleValue: (base) => ({
            ...base,
            color: '#000',
        }),
        input: (base) => ({
            ...base,
            color: '#adb5bd',
            height: "45px",
            margin: '0px',
            padding: '0px !important',

        }),
        menu: (base) => ({
            ...base,
            backgroundColor: '#fff',
        }),
        option: (base, state) => ({
            ...base,
            color: state.isSelected ? '#3a5a6c' : null,
            backgroundColor: state.isFocused ? '#27485b73' : null,
            "&:active": {
                backgroundColor: "#27485b73"
            },
        }),
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(file);
            setUserPhoto(imageUrl); // Atualiza a foto em tempo real
        }
    };

    return (
        <>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>


            <div className="fade-in w-100 mb-5">
                <Link onClick={() => navigate(-1)} className="btn text-primary link border-0 fs-6">
                    <i className="bi bi-chevron-left"></i> Voltar
                </Link>

                <div className="d-flex justify-content-center">
                    <form onSubmit={handleSubmit(onSubmit)} className="w-50 mt-1 d-grid">

                        <input
                            type="file"
                            style={{ display: "none" }}
                            ref={fileInputRef}
                            onChange={handleImageChange}
                        />

                        <div className="d-flex justify-content-center align-items-center mb-3">
                            {userPhoto ? (
                                <img
                                    src={userPhoto.includes('blob') ? userPhoto : `${process.env.REACT_APP_API_URL}/fotos/${userPhoto}`}
                                    alt="Foto do usuário"
                                    className="cursor-pointer perfil-foto"
                                    onClick={() => fileInputRef.current && fileInputRef.current.click()}
                                />
                            ) : (
                                <i
                                    className="bi bi-person-circle d-block text-primary"
                                    style={{ fontSize: '7rem' }}
                                    onClick={() => fileInputRef.current && fileInputRef.current.click()}
                                ></i>
                            )}
                            <span
                                className="btn btn-dark rounded-5 text-white px-2 py-1"
                                style={{ position: 'relative', top: 41, left: -40}}
                                onClick={() => fileInputRef.current && fileInputRef.current.click()}
                            ><i className="bi bi-camera-fill"></i>
                            </span>
                        </div>


                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label text-primary">Nome</label>
                                <input {...register('nome_completo', { required: true })} type="text" className="form-control" />
                                {errors.nome && <span className="text-primary fs-7">Nome é obrigatório</span>}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label text-primary">Unidade</label>
                                <Controller
                                    name="unidade"
                                    control={control}
                                    defaultValue={[]}
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={dataUnidades.map((value) => {
                                                return {
                                                    value: value.nome,
                                                    label: `${value.nome}`,
                                                };
                                            })}
                                            value={field.value}
                                            styles={customStyles}
                                            onChange={value => field.onChange(value)}
                                            placeholder="Selecione uma unidade"
                                            noOptionsMessage={() => "Nenhuma unidade encontrada."}
                                        />
                                    )}
                                />
                                {errors.unidade && <span className='text-primary fs-7'>Selecione uma unidade.</span>}
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <label className="form-label text-primary">Email</label>
                                <input {...register('email', { required: true })} type="email" className="form-control" />
                                {errors.email && <span className="text-primary fs-7">Email é obrigatório</span>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="form-label text-primary">Senha Atual</label>
                                <input {...register('senhaAtual')} type="password" placeholder="Digite apenas se quiser trocar a senha" className="form-control" />
                                {errors.senhaAtual && <span className="text-primary fs-7">Senha atual é obrigatória</span>}
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="form-label text-primary">Nova Senha</label>
                                <input {...register('novaSenha')} type="password" className="form-control" />
                            </div>
                        </div>

                        <button type="submit" className="btn btn-primary text-white mx-auto">Salvar</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Configuracoes;

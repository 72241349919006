import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { useForm, Controller } from "react-hook-form";
import Api from '../Auth/Api';
import { Toast, Modal } from 'react-bootstrap';
import TextareaAutosize from "react-textarea-autosize";
import { decodeJWT, formatTime } from '../Components/jwtUtils';

function Dashboard() {

    const token = JSON.parse(localStorage.getItem("user_token"))
    const nivel = decodeJWT(token).nivel;

    const [file0Selected, setFile0Selected] = useState(false);
    const [file1Selected, setFile1Selected] = useState(false);
    const [file2Selected, setFile2Selected] = useState(false);
    const [moduloAddFile, setModuloAddFile] = useState(false);
    const [cursoAddFile, setCursoAddFile] = useState(false);

    const [selectCursos, setSelectCursos] = useState(false);

    const [uploadAulaProgress, setUploadAulaProgress] = useState(0);
    const [uploadCursoProgress, setUploadCursoProgress] = useState(0);

    const [reloadData, setReloadData] = useState(false);
    const [reloadDataTopicos, setReloadDataTopicos] = useState(false);

    const [date, setDate] = useState(new Date());

    const [showModalAddCurso, setShowModalAddCurso] = useState(false);
    const [showModalAddUsuario, setShowModalAddUsuario] = useState(false);
    const [showModalAddAula, setShowModalAddAula] = useState(false);
    const [showModalAddModulo, setShowModalAddModulo] = useState(false);
    const [showModalAddTopico, setShowModalAddTopico] = useState(false);

    const [toast, setToast] = useState({});
    const [showToast, setShowToast] = useState(false);

    // Abreviar o dia da semana
    const formatShortWeekday = (locale, date) => {
        // Retorna os primeiros 3 caracteres do dia da semana
        return date.toLocaleString(locale, { weekday: 'short' }).slice(0, 3);
    };

    // function gerarCodigo() {
    //     return Math.floor(Math.random() * 1000000).toString().padStart(6, "0");
    // }

    const [dataUnidades, setDataUnidades] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-unidades`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataUnidades(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token]);

    const [dataCursos, setDataCursos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-cursos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataCursos(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token, toast, nivel]);

    const [idCurso, setIdCurso] = useState();
    const [dataModulos, setDataModulos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-modulos/${idCurso}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataModulos(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }

        idCurso && fetchData();

    }, [token, idCurso, reloadData]);

    const [idTopico, setIdTopico] = useState();
    const [dataTopicos, setDataTopicos] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-topicos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataTopicos(response.data);
            } catch (error) {
                console.error(error.response.data.message)
            }
        }
        fetchData();
    }, [token, idTopico, reloadDataTopicos]);

    const [dataAulasAdicionadas, setDataAulasAdicionadas] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/ultimas-aulas`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataAulasAdicionadas(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token]);

    const [dataUsuariosTutor, setDataUsuariosTutor] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-usuarios-tutor`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataUsuariosTutor(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        nivel === 'Tutor' && fetchData();
    }, [token, nivel]);

    // Buscar pelo modulo selecionando o curso desejado na tela inicial
    const [idCursoInicio, setIdCursoInicio] = useState();
    const [dataModulosInicio, setDataModulosInicio] = useState([]);
    useEffect(() => {

        if (idCursoInicio) {
            async function fetchData() {
                try {
                    const response = await Api.get(`api/listar-modulos/${idCursoInicio}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    setDataModulosInicio(response.data);
                } catch (error) {
                    console.error(error.response.data.message)
                }
            }
            fetchData();
        }

    }, [token, idCursoInicio]);

    const handleCursoChange = (selectedCurso) => {
        setDataModulosInicio([]);
        if (selectedCurso && selectedCurso.value) {
            setIdCursoInicio(selectedCurso.value);
        }
    };

    const [dataTutor, setDataTutor] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const response = await Api.get(`api/listar-usuarios/nivel/Tutor/todos`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setDataTutor(response.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [token, toast]);

    /////////////////////// Add Topicos ///////////////////////////
    const {
        register: register_T,
        handleSubmit: handleSubmit_T,
        reset: reset_T,
        formState: { errors: errors_T },
    } = useForm();

    const onSubmitAddTopico = async (data) => {

        try {
            const response = await Api.post("/api/novo-topico", data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message })
            reset_T();

        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao criar novo usuário:", error.response.data.message);
        }

        setShowToast(true);
    };

    /////////////////////// Add Usuarios ///////////////////////////
    const {
        register: register_P,
        handleSubmit: handleSubmit_P,
        control: control_P,
        reset: reset_P,
        setValue: setValue_P,
        formState: { errors: errors_P },
    } = useForm();

    let dataNiveis = [];
    if (nivel === 'Administrador') {
        dataNiveis = [
            { value: 'Aluno', label: 'Aluno' },
            { value: 'Tutor', label: 'Tutor' },
            { value: 'Administrador', label: 'Administrador' },
        ];
    } else {
        dataNiveis = [
            { value: 'Aluno', label: 'Aluno' },
        ];
    }


    const onSubmitAddParticipante = async (data) => {

        data.cursos = (data.cursos || []).map((curso) => curso.value);

        try {
            const response = await Api.post("/api/novo-usuario", data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message })
            reset_P();
            setValue_P("nivel", "");
            setValue_P("codigo", "");
            setShowModalAddUsuario(false)

        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao criar novo usuário:", error.response.data.message);
        }

        setShowToast(true);
    };
    /////////////////////// Fim Add Participantes


    ////////////////////// Add Curso
    const {
        register: register_C,
        handleSubmit: handleSubmit_C,
        control: control_C,
        reset: reset_C,
        setValue: setValue_C,
        formState: { errors: errors_C },
    } = useForm();

    const onSubmitAddCurso = async (data) => {
        const formData = new FormData();

        Object.keys(data).forEach(key => {
            const value = data[key];
            if (typeof value === 'object' && value !== null && !(value instanceof FileList)) {
                formData.append(key, JSON.stringify(value));
            } else if (value instanceof FileList) {
                Array.from(value).forEach(file => {
                    formData.append(key, file);
                });
            } else {
                formData.append(key, value);
            }
        });

        try {
            const response = await Api.post("/api/novo-curso", formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setUploadCursoProgress(percentCompleted + "%")
                    if (percentCompleted === 100) {
                        setTimeout(() => {
                            setUploadCursoProgress('Aguarde... finalizando...')
                        }, 1000);
                    }
                }
            });

            setToast({ header: 'Sucesso!', body: response.data.message })
            reset_C()
            setValue_C("thumbnail", "")
            setValue_C("idTutor", "")
            setCursoAddFile(false)
            setUploadCursoProgress(0)
            setShowModalAddCurso(false)

        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao criar novo curso:", error);
        }
        setShowToast(true);
    };
    ////////////////////// Fim Add Curso


    ////////////////////// Add Módulo
    const {
        register: register_M,
        handleSubmit: handleSubmit_M,
        control: control_M,
        reset: reset_M,
        setValue: setValue_M,
        formState: { errors: errors_M },
    } = useForm();

    const onSubmitAddModulo = async (data) => {
        const formData = new FormData();

        Object.keys(data).forEach(key => {
            const value = data[key];
            if (typeof value === 'object' && value !== null && !(value instanceof FileList)) {
                formData.append(key, JSON.stringify(value));
            } else if (value instanceof FileList) {
                Array.from(value).forEach(file => {
                    formData.append(key, file);
                });
            } else {
                formData.append(key, value);
            }
        });

        try {
            const response = await Api.post("/api/novo-modulo", formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setToast({ header: 'Sucesso!', body: response.data.message })
            reset_M();
            setValue_M("thumbnail", "");

        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao criar novo módulo:", error);
        }
        setShowToast(true);
    };

    ////////////////////// Add Aula
    const {
        register: register_A,
        handleSubmit: handleSubmit_A,
        control: control_A,
        reset: reset_A,
        setValue: setValue_A,
        formState: { errors: errors_A },
    } = useForm();

    const onSubmitAddAula = async (data) => {

        const formData = new FormData();

        Object.keys(data).forEach(key => {
            const value = data[key];
            if (typeof value === 'object' && value !== null && !(value instanceof FileList)) {
                formData.append(key, JSON.stringify(value));
            } else if (value instanceof FileList) {
                Array.from(value).forEach(file => {
                    formData.append(key, file)
                });
            } else {
                formData.append(key, value)
            }
        });

        try {
            const response = await Api.post("/api/novo-aula", formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setUploadAulaProgress(percentCompleted + "%")
                    if (percentCompleted === 100) {
                        setTimeout(() => {
                            setUploadAulaProgress('Aguarde... finalizando...')
                        }, 1000)
                    }
                }
            });

            setToast({ header: 'Sucesso!', body: response.data.message })
            reset_A()
            setFile0Selected(false)
            setFile1Selected(false)
            setFile2Selected(false)
            setUploadAulaProgress(0)
            setShowModalAddAula(false)

        } catch (error) {
            setToast({ header: 'Ops!', body: error.response.data.message })
            console.error("Erro ao criar nova aula:", error)
        }
        setShowToast(true)
    };

    const customStyles = {
        control: (base, state) => ({
            ...base,
            fontSize: "1rem",
            backgroundColor: "#ffffff",
            borderRadius: "0.25rem",
            boxShadow: state.isFocused ? "0 0 0 0.25rem #27485b73" : null,
            borderColor: "#233734",
            "&:hover": {
                borderColor: "#3a6883"
            },
        }),
        singleValue: (base) => ({
            ...base,
            color: '#000',
        }),
        input: (base) => ({
            ...base,
            color: '#adb5bd',
            height: "52px",
            margin: '0px',
            padding: '0px !important',

        }),
        menu: (base) => ({
            ...base,
            backgroundColor: '#fff',
        }),
        option: (base, state) => ({
            ...base,
            color: state.isSelected ? '#3a5a6c' : null,
            backgroundColor: state.isFocused ? '#27485b73' : null,
            "&:active": {
                backgroundColor: "#27485b73"
            },
        }),
    };

    const handleKeyPress = (e) => {
        const input = e.target;
        setTimeout(() => {
            input.value = formatTime(input.value);
        }, 0);
    };

    return (
        <div className="fade-in w-100 mb-5">

            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide style={{ position: 'fixed', left: '95%', bottom: '10px', transform: 'translateX(-95%)', zIndex: 9999 }}>
                <Toast.Header>
                    <strong className="mr-auto">{toast.header}</strong>
                </Toast.Header>
                <Toast.Body>{toast.body}</Toast.Body>
            </Toast>

            <div className="row" style={{ marginBottom: '100px' }}>

                <div className="col-12 col-xl-8 col-xxl-8">

                    <div className="card-flag rounded w-100 overflow-hidden mb-4 d-none d-md-block">
                        <img src="./../assets/flag.png" alt="" className="card-img w-100 img-banner" />
                    </div>

                    <div className="container-fluid mt-3 mt-md-0">
                        <div className="row mb-4">
                            {nivel === 'Administrador' &&
                                <div className="col-12 col-xl-3 col-md-6 py-1">
                                    <button type="button" className="d-flex align-items-center justify-content-center btn btn-primary text-white w-100 fw-semibold"
                                        onClick={() => { setShowModalAddAula(true); setUploadAulaProgress(0); reset_A() }}>
                                        <i className="bi bi-plus fs-4"></i> Aula/Módulo
                                    </button>
                                </div>
                            }
                            {nivel === 'Administrador' &&
                                <div className="col-12 col-xl-3 col-md-6 py-1">
                                    <button type="button" className="d-flex align-items-center justify-content-center btn btn-primary text-white w-100 fw-semibold"
                                        onClick={() => { setShowModalAddCurso(true); setUploadCursoProgress(0); reset_C() }}>
                                        <i className="bi bi-plus fs-4"></i> Curso
                                    </button>
                                </div>
                            }

                            {(nivel === 'Administrador' || 'Tutor') &&
                                <div className="col-12 col-xl-3 col-md-6 py-1">
                                    <button type="button" className="d-flex align-items-center justify-content-center btn btn-primary text-white w-100 fw-semibold"
                                        onClick={() => { setShowModalAddUsuario(true); reset_P() }}>
                                        <i className="bi bi-plus fs-4"></i> Usuário
                                    </button>
                                </div>
                            }
                            <div className="col-12 col-xl-3 col-md-6 py-1">
                                <Link to="/provas-extras">
                                    <button type="button" className="d-flex align-items-center justify-content-center btn btn-primary text-white w-100 fw-semibold">
                                        <i className="bi bi-journal-medical fs-4 me-2"></i> Provas Extras
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>


                    <div className="body rounded mb-2 bg-white px-3 py-3">
                        <section className="w-100">
                            <div className="d-flex flex-column">
                                <div className="row d-flex align-items-center justify-content-between">
                                    <div className="col-12">
                                        <div className="inner-addon right-addon">
                                            <Select
                                                options={dataCursos.map((value) => {
                                                    return {
                                                        value: value.id,
                                                        label: `${value.nome}`,
                                                    };
                                                })}
                                                styles={customStyles}
                                                placeholder="Selecione o curso desejado"
                                                noOptionsMessage={() => "Nenhum curso encontrado."}
                                                onChange={handleCursoChange} // Passar a função para o onChange
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="d-flex flex-column mt-2">
                            {dataModulosInicio.map((modulo) => (
                                <div className="card mb-4 py-3 px-4 bg-grey-light" key={modulo.id}>
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex flex-column ms-3 w-100">
                                            <h6 className="fw-medium text-dark mb-0 fs-6">{modulo.nome}</h6>
                                            <hr />
                                            <div className="d-flex align-items-center justify-content-between fs-7">
                                                <span className="text-dark fw-normal text-uppercase">{modulo.descricao ? modulo.descricao : "Sem descrição"}</span>
                                                <span className="text-primary cursor-pointer link fw-medium fs-6"><Link to={`/aulas/${modulo.id}`}>Visualizar</Link></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </section>

                    </div>

                </div>

                <div className="col-md-12 col-xl-4 col-xxl-4">

                    <div className="pb-3">
                        <Calendar
                            formatShortWeekday={formatShortWeekday}
                            onChange={setDate}
                            value={date}
                        />
                        <div className="my-3">
                            <Link to="/calendario">
                                <button type="button" className="btn btn-outline-primary rounded py-2 w-100" tabIndex="0">
                                    Ver calendário completo
                                </button>
                            </Link>
                        </div>
                    </div>

                    {nivel === 'Administrador' &&
                        <div className="classes rounded bg-primary flex-fill text-white p-4">
                            <span className="d-flex justify-content-center fs-5 fw-500 text-center">Aulas Adicionadas</span>
                            <div className="container-fluid mt-2">
                                <div className="row">
                                    {
                                        dataAulasAdicionadas.map((value, index) => {
                                            return (
                                                <div key={value.id} className="d-flex flex-column rounded bg-white text-black text-dark p-3 my-2">
                                                    <span className="fw-normal fs-6 text-pretty">{value.titulo}</span>
                                                    <span className="fw-normal fs-7 text-pretty">{value.nome_curso}</span>
                                                </div>
                                            )
                                        })
                                    }
                                    {/* <Link to="/lista-alunos" className="btn btn-link text-white fs-6 mt-3 fw-light">Visualizar</Link> */}
                                </div>
                            </div>
                        </div>
                    }

                    {nivel === 'Tutor' &&
                        <div className="classes rounded bg-primary flex-fill text-white p-4">
                            <span className="d-flex justify-content-center fs-5 fw-500 text-center">Lista de Alunos</span>
                            <div className="container-fluid mt-2">
                                <div className="row">
                                    {
                                        dataUsuariosTutor.map((value, index) => {
                                            return (
                                                <div key={value.id} className="d-flex flex-column rounded bg-white p-3 my-2">
                                                    <span className="fw-normal fs-6 text-pretty text-dark">{value.nome_completo}</span>
                                                </div>
                                            )
                                        })
                                    }
                                    {/* <Link to="/lista-alunos" className="btn btn-link text-white fs-6 mt-3 fw-light">Visualizar</Link> */}
                                </div>
                            </div>
                        </div>
                    }

                </div>

                <Modal size="lg" show={showModalAddTopico} onHide={() => { setReloadDataTopicos(!reloadDataTopicos); setShowModalAddTopico(false); setShowModalAddCurso(true) }}>
                    <form onSubmit={handleSubmit_T(onSubmitAddTopico)}>
                        <Modal.Header closeButton>
                            <span className="fw-semibold fs-4 text-primary">Adicionar Tema</span>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="body">
                                            <div className="d-flex">
                                                <div className="d-flex flex-column w-100">
                                                    <div className="row">
                                                        <div className="col-12 col-md-12">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Nome</label>
                                                                <input type="text" {...register_T('nome', { required: true })} placeholder="Título do tema" className="form-control border-primary" />
                                                                {errors_T.nome && <span className='text-primary fs-7'>Tema é obrigatório.</span>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer className="justify-content-center">
                            <button type="submit" className="btn btn-primary text-white px-5 py-2">Salvar</button>
                            <button type="button" className="btn btn-outline-primary text-primary px-5 py-2" onClick={() => { setReloadDataTopicos(!reloadDataTopicos); setShowModalAddTopico(false); setShowModalAddCurso(true) }}>Cancelar</button>
                        </Modal.Footer>
                    </form>
                </Modal>

                <Modal size="lg" show={showModalAddCurso} onHide={() => { setShowModalAddCurso(false); setIdTopico("") }}>
                    <form onSubmit={handleSubmit_C(onSubmitAddCurso)}>
                        <Modal.Header closeButton>
                            <span className="fw-semibold fs-4 text-primary">Adicionar Curso</span>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="body">
                                            <div className="d-flex">
                                                <div className="d-flex flex-column w-100">
                                                    <div className="row">

                                                        {/* <div className="col-12 col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Nome do diploma</label>
                                                                <input type="text" {...register_C('nome_certificado', { required: true })} placeholder="Nome do diploma" className="form-control border-primary" />
                                                                {errors_C.nome_certificado && <span className='text-primary fs-7'>Nome do diploma é obrigatório.</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-8">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Descrição do diploma</label>
                                                                <input type="text" {...register_C('descricao_certificado', { required: true })} placeholder="Descrição do diploma" className="form-control border-primary" />
                                                                {errors_C.descricao_certificado && <span className='text-primary fs-7'>Descrição do diploma é obrigatório.</span>}
                                                            </div>
                                                        </div>

                                                        <hr></hr> */}

                                                        <div className="col-12 col-md-12">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Nome</label>
                                                                <input type="text" {...register_C('nome', { required: true })} placeholder="Nome do curso" className="form-control border-primary" />
                                                                {errors_C.nome && <span className='text-primary fs-7'>Nome do curso é obrigatório.</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-12">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Descrição</label>
                                                                <input type="text" {...register_C('descricao')} placeholder="Breve descrição do curso" className="form-control border-primary" />
                                                            </div>
                                                        </div>

                                                        <div className="col-9 col-md-10">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Tema</label>
                                                                <Controller
                                                                    name="idTopico"
                                                                    control={control_C}
                                                                    defaultValue={[]}
                                                                    rules={{ required: true }}
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            options={dataTopicos.map((value) => {
                                                                                return {
                                                                                    value: value.id,
                                                                                    label: `${value.nome}`,
                                                                                };
                                                                            })}
                                                                            value={field.value}
                                                                            styles={customStyles}
                                                                            rules={{ required: true }}
                                                                            onChange={value => {
                                                                                field.onChange(value)
                                                                                if (value && value.value) {
                                                                                    setIdTopico(value.value)
                                                                                }
                                                                            }}
                                                                            placeholder="Selecione o tema"
                                                                            noOptionsMessage={() => "Nenhum tema encontrado."}
                                                                        />
                                                                    )}
                                                                />
                                                                {errors_C.topico && <span className='text-primary fs-7'>Selecione um tema.</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-3 col-md-2">
                                                            <div className="d-flex flex-column h-100">
                                                                <label className="form-label opacity-0">button</label>
                                                                <span className="btn btn-outline-primary btn-height-58px mb-3 d-flex align-items-center justify-content-center"
                                                                    onClick={() => {
                                                                        setShowModalAddTopico(true);
                                                                        setShowModalAddCurso(false);
                                                                    }}
                                                                >
                                                                    <i className="bi bi-plus"></i>
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Thumbnail</label>
                                                                <label htmlFor="thumbnail" className={`form-control label-input ${cursoAddFile ? 'border-success bg-success' : 'border-primary bg-primary'} text-white fw-medium text-center`}><i className="bi bi-card-image me-2"></i> Enviar Imagem</label>
                                                                <Controller
                                                                    control={control_C}
                                                                    name="thumbnail"
                                                                    rules={{ required: true }}
                                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                        <input
                                                                            id='thumbnail'
                                                                            type="file"
                                                                            onBlur={onBlur}
                                                                            ref={ref}
                                                                            onChange={(e) => {
                                                                                const files = e.target.files;
                                                                                onChange(files);
                                                                                if (files && files.length > 0) {
                                                                                    setCursoAddFile(true);
                                                                                } else {
                                                                                    setCursoAddFile(false);
                                                                                }
                                                                            }}
                                                                        />
                                                                    )}
                                                                />
                                                                {errors_C.thumbnail && <span className='text-primary fs-7'>Imagem do curso é obrigatório.</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-8">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Tutor</label>
                                                                <Controller
                                                                    name="idTutor"
                                                                    control={control_C}
                                                                    defaultValue={[]}
                                                                    rules={{ required: true }}
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            options={dataTutor.map((value) => {
                                                                                return {
                                                                                    value: value.id,
                                                                                    label: `${value.nome_completo}`,
                                                                                };
                                                                            })}
                                                                            value={field.value}
                                                                            styles={customStyles}
                                                                            onChange={value => field.onChange(value)}
                                                                            placeholder="Selecione o Tutor"
                                                                            isSearchable
                                                                            noOptionsMessage={() => "Nenhum tutor encontrado."}
                                                                        />
                                                                    )}
                                                                />
                                                                {errors_C.idTutor && <span className='text-primary fs-7'>Tutor é obrigatório.</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-12">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Resumo</label>
                                                                <TextareaAutosize {...register_C('resumo')} placeholder="Informações gerais sobre o curso" className="form-control border-primary"></TextareaAutosize>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-4">
                                                            <div className="mb-1">
                                                                <label className="form-label text-primary fw-medium">Disponibilidade do curso</label>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-8">
                                                            <div className="mb-3">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className='w-50 pe-2'>
                                                                        <label className="form-label d-block text-dark">Data de Início</label>
                                                                        <input
                                                                            type="date"
                                                                            {...register_C('dataInicio', { required: true })}
                                                                            className="form-control border-primary"
                                                                        />
                                                                        {errors_C.dataInicio && <span className='text-primary fs-7'>Data de início é obrigatória.</span>}
                                                                    </div>
                                                                    <div className='w-50'>
                                                                        <label className="form-label d-block text-dark">Data Final</label>
                                                                        <input
                                                                            type="date"
                                                                            {...register_C('dataFinal', { required: true })}
                                                                            className="form-control border-primary"
                                                                        />
                                                                        {errors_C.dataFinal && <span className='text-primary fs-7'>Data final é obrigatória.</span>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-4">
                                                            <div className="mb-1">
                                                                <label className="form-label text-primary fw-medium">Tipo do curso</label>
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-8">
                                                            <div className="mb-3">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className='w-50 pe-2'>
                                                                        <label className="form-label d-block text-dark">Híbrido</label>
                                                                        <input
                                                                            type="radio"
                                                                            {...register_C('tipo', { required: true })}
                                                                            value="misto"
                                                                            className="form-check-input"
                                                                        />
                                                                    </div>
                                                                    <div className='w-50'>
                                                                        <label className="form-label d-block text-dark">Online</label>
                                                                        <input
                                                                            type="radio"
                                                                            {...register_C('tipo', { required: true })}
                                                                            value="online"
                                                                            className="form-check-input"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {errors_C.tipo && <span className='text-primary fs-7'>Tipo do curso é obrigatório.</span>}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="progress col-12">
                                                        <div className="progress-bar" role="progressbar" style={{ width: `${uploadCursoProgress}` }} aria-valuenow={uploadCursoProgress} aria-valuemin="0" aria-valuemax="100">{uploadCursoProgress}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer className="justify-content-center">
                            <button type="submit" className="btn btn-primary text-white px-5 py-2">Salvar</button>
                            <button type="button" className="btn btn-outline-primary text-primary px-5 py-2 justify-content-center" onClick={() => setShowModalAddCurso(false)}>Cancelar</button>
                        </Modal.Footer>
                    </form>
                </Modal>

                <Modal size="lg" show={showModalAddUsuario} onHide={() => setShowModalAddUsuario(false)}>
                    <form onSubmit={handleSubmit_P(onSubmitAddParticipante)}>
                        <Modal.Header closeButton>
                            <span className="fw-semibold fs-4 text-primary"> Adicionar Usuário </span>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="body">
                                            <div className="d-flex">
                                                <div className="d-flex flex-column w-100">

                                                    <div className="row mb-3">

                                                        <div className="col-12 col-md-8">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Nome</label>
                                                                <input type="text" {...register_P("nome_completo", { required: true })} placeholder="Nome do usuário" className="form-control border-primary" />
                                                                {errors_P.nome_completo && <span className='text-primary fs-7'>Nome completo é obrigatório.</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-4">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Matrícula</label>
                                                                <input type="number" {...register_P("codigo", { required: true })} placeholder="Digite a matrícula" className="form-control border-primary" />
                                                                {errors_P.codigo && <span className='text-primary fs-7'>Matrícula é obrigatório.</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-6">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Email</label>
                                                                <input type="email" {...register_P("email", { required: true })} placeholder="email@email.com" className="form-control border-primary" />
                                                                {errors_P.email && <span className='text-primary fs-7'>Email é obrigatório.</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-6">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Cargo</label>
                                                                <input type="text" {...register_P("cargo", { required: true })} placeholder="Digite o cargo" className="form-control border-primary" />
                                                                {errors_P.cargo && <span className='text-primary fs-7'>Cargo é obrigatório.</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-6">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Tipo Usuário</label>
                                                                <Controller
                                                                    name="nivel"
                                                                    control={control_P}
                                                                    defaultValue={[]}
                                                                    rules={{ required: true }}
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            options={dataNiveis}
                                                                            value={field.value}
                                                                            styles={customStyles}
                                                                            onChange={value => {
                                                                                if (value.label === 'Aluno') {
                                                                                    setSelectCursos(true);
                                                                                } else {
                                                                                    setSelectCursos(false);
                                                                                }
                                                                                field.onChange(value);
                                                                            }}
                                                                            placeholder="Selecione tipo do usuário"
                                                                        />
                                                                    )}
                                                                />
                                                                {errors_P.nivel && <span className='text-primary fs-7'>Tipo de usuário é obrigatório.</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-6">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Unidade</label>
                                                                <Controller
                                                                    name="unidade"
                                                                    control={control_P}
                                                                    defaultValue={[]}
                                                                    rules={{ required: true }}
                                                                    render={({ field }) => (
                                                                        <Select
                                                                            {...field}
                                                                            options={dataUnidades.map((value) => {
                                                                                return {
                                                                                    value: value.nome,
                                                                                    label: `${value.nome}`,
                                                                                };
                                                                            })}
                                                                            value={field.value}
                                                                            styles={customStyles}
                                                                            onChange={value => field.onChange(value)}
                                                                            placeholder="Selecione uma unidade"
                                                                            noOptionsMessage={() => "Nenhuma unidade encontrada."}
                                                                        />
                                                                    )}
                                                                />
                                                                {errors_P.unidade && <span className='text-primary fs-7'>Selecione uma unidade.</span>}
                                                            </div>
                                                        </div>

                                                        {selectCursos &&
                                                            <div className="col-12 col-md-12">
                                                                <div className="mb-3">
                                                                    <label className="form-label text-primary fw-medium">Cursos</label>
                                                                    <Controller
                                                                        name="cursos"
                                                                        control={control_P}
                                                                        defaultValue={[]}
                                                                        rules={{ required: true }}
                                                                        render={({ field }) => (
                                                                            <Select
                                                                                {...field}
                                                                                isMulti
                                                                                options={dataCursos.map((value) => {
                                                                                    return {
                                                                                        value: value.id,
                                                                                        label: `${value.nome}`,
                                                                                    };
                                                                                })}
                                                                                value={field.value}
                                                                                styles={customStyles}
                                                                                onChange={value => field.onChange(value)}
                                                                                noOptionsMessage={() => "Nenhum curso encontrado."}
                                                                                placeholder="Selecione os cursos"
                                                                            />
                                                                        )}
                                                                    />
                                                                    {errors_P.cursos && <span className='text-primary fs-7'>Selecione pelo menos um curso.</span>}
                                                                </div>
                                                            </div>
                                                        }

                                                        <div className="col-12 col-md-6">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Senha</label>
                                                                <input type="password" {...register_P("senha", { required: true })} placeholder="********" className="form-control border-primary" />
                                                                {errors_P.senha && <span className='text-primary fs-7'>Senha é obrigatório.</span>}
                                                            </div>
                                                        </div>

                                                        <div className="col-12 col-md-6">
                                                            <div className="mb-3">
                                                                <label className="form-label text-primary fw-medium">Confirme a senha</label>
                                                                <input type="password" {...register_P("confirme_senha", { required: true })} placeholder="********" className="form-control border-primary" />
                                                                {errors_P.confirme_senha && <span className='text-primary fs-7'>Confirme a senha.</span>}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer className="d-flex justify-content-center bg-light">
                            <button type="submit" className="btn btn-primary text-white px-5 py-2">Salvar</button>
                            <button type="button" className="btn btn-outline-primary text-primary px-5 py-2" onClick={() => setShowModalAddUsuario(false)}>Cancelar</button>
                        </Modal.Footer>
                    </form>
                </Modal>

                <Modal size="lg" show={showModalAddAula} onHide={() => { setShowModalAddAula(false); setIdCurso("") }}>
                    <Modal.Header closeButton>
                        <span className="fw-semibold fs-4 text-primary">Adicionar Aula/Módulo</span>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit_A(onSubmitAddAula)}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="body">
                                            <div className="d-flex mb-5">
                                                <div className="d-flex flex-column w-100">
                                                    <div className="row">

                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className='col-12 h-100 red-bg d-flex align-items-center justify-content-center rounded-3'>
                                                                    <i className="bi bi-cloud-upload icon-large text-white"></i>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-8 mt-3">
                                                                <div className="row">
                                                                    <div className="col-12 col-md-12">
                                                                        <div className="mb-3">
                                                                            <input type="text" {...register_A("titulo", { required: true })} placeholder="Título da aula" className="form-control border-primary" />
                                                                            {errors_A.titulo && <span className='text-primary fs-7'>Título é obrigatório.</span>}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-md-12">
                                                                        <div className="mb-3">
                                                                            <TextareaAutosize type="text" {...register_A("descricao", { required: true })} placeholder="Descrição da aula" className="form-control border-primary" />
                                                                            {errors_A.descricao && <span className='text-primary fs-7'>Descriação é obrigatório.</span>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='row mt-3'>
                                                            <div className="col-12 col-md-12">
                                                                <div className="mb-3">
                                                                    <label className="form-label text-primary fw-medium">Curso</label>
                                                                    <Controller
                                                                        name="curso"
                                                                        control={control_A}
                                                                        defaultValue={[]}
                                                                        rules={{ required: true }}
                                                                        render={({ field }) => (
                                                                            <Select
                                                                                {...field}
                                                                                options={dataCursos.map((value) => {
                                                                                    return {
                                                                                        value: value.id,
                                                                                        label: `${value.nome}`,
                                                                                    };
                                                                                })}
                                                                                value={field.value}
                                                                                styles={customStyles}
                                                                                rules={{ required: true }}
                                                                                onChange={value => {
                                                                                    field.onChange(value)
                                                                                    if (value && value.value) {
                                                                                        setValue_A("modulo", "")
                                                                                        setIdCurso(value.value)
                                                                                        setDataModulos([])
                                                                                        setValue_M("cursos_nome", value.label)
                                                                                        setValue_M("idCurso", value.value)
                                                                                    }
                                                                                }}
                                                                                placeholder="Selecione o curso"
                                                                                noOptionsMessage={() => "Nenhum curso encontrado."}
                                                                            />
                                                                        )}
                                                                    />
                                                                    {errors_A.curso && <span className='text-primary fs-7'>Selecione um curso.</span>}
                                                                </div>
                                                            </div>

                                                            {idCurso && (
                                                                <>
                                                                    <div className="col-9 col-md-10">
                                                                        <div className="mb-3">
                                                                            <label className="form-label text-primary fw-medium">Módulo</label>
                                                                            <Controller
                                                                                name="modulo"
                                                                                control={control_A}
                                                                                defaultValue={[]}
                                                                                rules={{ required: true }}
                                                                                render={({ field }) => (
                                                                                    <Select
                                                                                        {...field}
                                                                                        options={dataModulos.map((value) => {
                                                                                            return {
                                                                                                value: value.id,
                                                                                                label: `${value.nome}`,
                                                                                            };
                                                                                        })}
                                                                                        value={field.value}
                                                                                        styles={customStyles}
                                                                                        onChange={value => field.onChange(value)}
                                                                                        placeholder="Selecione uma módulo"
                                                                                        noOptionsMessage={() => "Nenhum módulo encontrada."}
                                                                                    />
                                                                                )}
                                                                            />
                                                                            {errors_A.modulo && <span className='text-primary fs-7'>Selecione um módulo.</span>}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3 col-md-2">
                                                                        <div className="d-flex flex-column h-100">
                                                                            <label className="form-label opacity-0">button</label>
                                                                            <span className="btn btn-outline-primary btn-height-58px mb-3 d-flex align-items-center justify-content-center"
                                                                                onClick={() => {
                                                                                    setShowModalAddModulo(true);
                                                                                    setShowModalAddAula(false);
                                                                                }}
                                                                            >
                                                                                <i className="bi bi-plus"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}


                                                        </div>
                                                        {idCurso && (
                                                            <div className='row mt-3'>
                                                                <div className="col-6 col-md-4">
                                                                    <div className="mb-3">
                                                                        <div className="card border-none">
                                                                            <p className='fs-7'>Arquivo da aula</p>
                                                                            <h5 className="card-header card-upload d-flex align-items-center justify-content-center ">
                                                                                <i className="bi bi-file-earmark-fill"></i>
                                                                            </h5>
                                                                            <div className="card-body d-flex justify-content-center bg-light">
                                                                                <p className="card-text fs-7">
                                                                                    <label htmlFor="file_0" className={`cursor-pointer ${file0Selected ? 'text-success' : 'text-primary'}`}> Fazer Upload  <i className="bi bi-cloud-upload"></i></label>
                                                                                    <Controller
                                                                                        control={control_A}
                                                                                        rules={{ required: true }}
                                                                                        name="file_0"
                                                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                                            <input
                                                                                                id='file_0'
                                                                                                type="file"
                                                                                                onBlur={onBlur}
                                                                                                ref={ref}
                                                                                                onChange={(e) => {
                                                                                                    const files = e.target.files;
                                                                                                    onChange(files);
                                                                                                    if (files && files.length > 0) {
                                                                                                        setFile0Selected(true);
                                                                                                    } else {
                                                                                                        setFile0Selected(false);
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 col-md-4 border-start border-primary">
                                                                    <div className="mb-3">
                                                                        <div className="card border-none">
                                                                            <p className='fs-7'>Arquivo Auxiliar 1</p>
                                                                            <h5 className="card-header card-upload d-flex align-items-center justify-content-center ">
                                                                                <i className="bi bi-file-earmark-fill"></i>
                                                                            </h5>
                                                                            <div className="card-body d-flex justify-content-center bg-light">
                                                                                <p className="card-text fs-7">
                                                                                    <label htmlFor="file_1" className={`cursor-pointer ${file1Selected ? 'text-success' : 'text-primary'}`}> Fazer Upload  <i className="bi bi-cloud-upload"></i></label>
                                                                                    <Controller
                                                                                        control={control_A}
                                                                                        name="file_1"
                                                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                                            <input
                                                                                                id='file_1'
                                                                                                type="file"
                                                                                                onBlur={onBlur}
                                                                                                ref={ref}
                                                                                                onChange={(e) => {
                                                                                                    const files = e.target.files;
                                                                                                    onChange(files);
                                                                                                    if (files && files.length > 0) {
                                                                                                        setFile1Selected(true);
                                                                                                    } else {
                                                                                                        setFile1Selected(false);
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-4">
                                                                    <div className="mb-3">
                                                                        <div className="card border-none">
                                                                            <p className='fs-7'>Arquivo Auxiliar 2</p>
                                                                            <h5 className="card-header card-upload d-flex align-items-center justify-content-center ">
                                                                                <i className="bi bi-file-earmark-fill"></i>
                                                                            </h5>
                                                                            <div className="card-body d-flex justify-content-center bg-light">
                                                                                <p className="card-text text-primary fs-7">
                                                                                    <label htmlFor="file_2" className={`cursor-pointer ${file2Selected ? 'text-success' : 'text-primary'}`}> Fazer Upload  <i className="bi bi-cloud-upload"></i></label>

                                                                                    <Controller
                                                                                        control={control_A}
                                                                                        name="file_2"
                                                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                                            <input
                                                                                                id='file_2'
                                                                                                type="file"
                                                                                                onBlur={onBlur}
                                                                                                ref={ref}
                                                                                                onChange={(e) => {
                                                                                                    const files = e.target.files;
                                                                                                    onChange(files);
                                                                                                    if (files && files.length > 0) {
                                                                                                        setFile2Selected(true);
                                                                                                    } else {
                                                                                                        setFile2Selected(false);
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {errors_A.file_0 && <span className='text-primary fs-7'>Arquivo da aula é obrigatório.</span>}
                                                            </div>
                                                        )}

                                                    </div>
                                                    <div className="progress col-12 mt-4">
                                                        <div className="progress-bar" role="progressbar" style={{ width: `${uploadAulaProgress}` }} aria-valuenow={uploadAulaProgress} aria-valuemin="0" aria-valuemax="100">{uploadAulaProgress}</div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mb-4">
                                <button type="submit" className="btn btn-primary text-white px-5 py-2">Salvar</button>
                                <button type="button" className="btn btn-outline-primary text-primary px-5 py-2 ms-4" onClick={() => { setShowModalAddAula(false); setIdCurso("") }}>Cancelar</button>
                            </div>

                        </form>
                    </Modal.Body>
                </Modal>

                <Modal size="lg" show={showModalAddModulo} onHide={() => { setShowModalAddModulo(false); setShowModalAddAula(true); setReloadData(!reloadData) }}>
                    <Modal.Header closeButton>
                        <span className="fw-semibold fs-4 text-primary">Adicionar Módulo</span>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit_M(onSubmitAddModulo)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="body">
                                        <div className="d-flex mb-5">
                                            <div className="d-flex flex-column w-100">
                                                <div className="row mb-3">
                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Curso</label>
                                                            <input readOnly placeholder="Escolha um curso" className='form-control border-primary' {...register_M('cursos_nome', { required: true })} />
                                                            <input type='hidden' {...register_M('idCurso', { required: true })} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-12 col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Titulo</label>
                                                            <input type="text" {...register_M('nome', { required: true })} placeholder="Título do Módulo" className="form-control border-primary" />
                                                            {errors_M.nome && <span className='text-primary fs-7'>Título do módulo é obrigatório.</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Thumbnail</label>
                                                            <label htmlFor="thumbnail" className={`form-control label-input-simple ${moduloAddFile ? 'border-success bg-success' : 'border-primary bg-primary'} text-white fw-medium text-center`}><i className="bi bi-card-image me-2"></i>Enviar Arquivo</label>
                                                            <Controller
                                                                control={control_M}
                                                                name="thumbnail"
                                                                rules={{ required: true }}
                                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                    <input
                                                                        id='thumbnail'
                                                                        type="file"
                                                                        onBlur={onBlur}
                                                                        ref={ref}
                                                                        onChange={(e) => {
                                                                            const files = e.target.files;
                                                                            onChange(files);
                                                                            if (files && files.length > 0) {
                                                                                setModuloAddFile(true);
                                                                            } else {
                                                                                setModuloAddFile(false);
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                            {errors_M.thumbnail && <span className='text-primary fs-7'>Imagem do módulo é obrigatório.</span>}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Carga Horária <span className="text-dark">(em horas)</span></label>
                                                            <input type="text" maxLength="5" onKeyDown={handleKeyPress} {...register_M('carga_horaria')} placeholder="00:00" className="form-control border-primary" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-12 col-md-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Resumo</label>
                                                            <TextareaAutosize {...register_M('resumo', { required: true })} placeholder="Resumo" className="form-control border-primary" />
                                                            {errors_M.resumo && <span className='text-primary fs-7'>Resumo é obrigatório.</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            <label className="form-label text-primary fw-medium">Descrição</label>
                                                            <TextareaAutosize {...register_M('descricao')} placeholder="Descrição sobre o módulo" className="form-control border-primary" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="d-flex justify-content-center mb-4">
                                <button type="submit" className="btn btn-primary text-white px-5 py-2">Salvar</button>
                                <button type="button" className="btn btn-outline-primary text-primary px-5 py-2 ms-4"
                                    onClick={() => { setShowModalAddModulo(false); setShowModalAddAula(true); setReloadData(!reloadData) }}
                                >Cancelar</button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>

            </div>
        </div >
    )
}

export default Dashboard;